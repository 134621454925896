import illustrations from '@utilitywarehouse/partner-ui-assets/lib/js/images/illustrations';

export const STORE_NAME = 'LandingPage';

export const APPOINTMENT_TYPE = {
  REMOTE: 'REMOTE',
  FACE_TO_FACE: 'FACE_TO_FACE',
  REMOTE_SUPPORT: 'REMOTE_SUPPORT',
  RESTORE: 'RESTORE',
};

export const APPOINTMENT_TYPE_OPTIONS = [
  {
    type: APPOINTMENT_TYPE.REMOTE,
    title: 'Set up a remote appointment',
    image: illustrations.i3.filled,
  },
  {
    type: APPOINTMENT_TYPE.FACE_TO_FACE,
    title: 'Set up a face-to-face appointment',
    image: illustrations.i8.filled,
  },
  {
    type: APPOINTMENT_TYPE.REMOTE_SUPPORT,
    title: 'Support a new Partner remotely',
    image: illustrations.i15.filled,
  },
  {
    type: APPOINTMENT_TYPE.RESTORE,
    title: 'Continue a previous appointment',
    image: illustrations.i6.filled,
  },
];

export const APPOINTMENT_TYPE_OPTIONS_NEW = [
  {
    type: APPOINTMENT_TYPE.FACE_TO_FACE,
    title: 'Face-to-face',
    description: '(Supporting Partner can still join remotely)',
    image: illustrations.i8.filled,
  },
  {
    type: APPOINTMENT_TYPE.REMOTE,
    title: 'Fully remote',
    image: illustrations.i3.filled,
  },
  {
    type: APPOINTMENT_TYPE.REMOTE_SUPPORT,
    title: 'Support a new Partner remotely',
    image: illustrations.i15.filled,
  },
  {
    type: APPOINTMENT_TYPE.RESTORE,
    title: 'Continue a previous appointment',
    image: illustrations.i6.filled,
  },
];

export const SUPPORT_SELECTION_OPTIONS = {
  present: 'PRESENT',
  remote: 'REMOTE',
  noSupport: 'NO_SUPPORT',
};

export const SUPPORT_SELECTION = [
  {
    key: SUPPORT_SELECTION_OPTIONS.present,
    value: "Yes, they're with me in person",
  },
  {
    key: SUPPORT_SELECTION_OPTIONS.remote,
    value: "Yes, they're supporting me remotely",
  },
  {
    key: SUPPORT_SELECTION_OPTIONS.noSupport,
    value: "No, I don't",
  },
];
