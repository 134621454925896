import { API_URL } from 'config';

import axios from 'axios';

export default function() {
  let url = `${API_URL}/application/products/mobile/tariffs`;
  const inst = axios.create();
  const config = {
    url,
    method: 'get',
  };

  return inst.request(config);
}
