import { tariffLabels } from './constants';

// On MAD 2020, new tariffs were introduced, requiring a special tariff calc.
//
// If more than a SIM is ordered, subsequent SIMs would cost `discountedPrice`.
// The following try to apply the full price only for the first `mobiles` item,
// while the other items will use `discountedPrice`.
//
// To maintain compatibility with older tariffs and Value, it fallbacks to
// `price` if there is no discounted price.
export const getMobileTariffPrice = (tariff, i, mobiles) => {
  return i === mobiles.findIndex(isUnlimited)
    ? tariff.price
    : tariff.discountedPrice || tariff.price;
};

const isUnlimited = (mobile) =>
  mobile.tariff.label &&
  [tariffLabels.UNLIMITED_PLUS, tariffLabels.UNLIMITED].includes(
    mobile.tariff.label
  );
