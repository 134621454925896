import * as types from './types';

export const fetchProviders = (service) => ({
  type: types.HOMEAUDIT_PROVIDERS_FETCH,
  service,
});

export const setProviders = (service, providers) => ({
  type: types.HOMEAUDIT_PROVIDERS_SET,
  service,
  providers,
});

export const setFieldValue = (service, label, value) => ({
  type: types.HOMEAUDIT_FIELD_VALUE_SET,
  service,
  label,
  value,
});

export const submitProviderDetails = (service) => ({
  type: types.HOMEAUDIT_DETAILS_SUBMIT,
  service,
});

export const setCombinedServices = (combined) => ({
  type: types.HOMEAUDIT_COMBINED_SET,
  combined,
});

export const updateValues = (savings) => ({
  type: types.HOMEAUDIT_UPDATE,
  savings,
});

export const setFields = (fields) => ({
  type: types.HOMEAUDIT_FIELDS_SET,
  fields,
});

export const setEditDialog = (open = false) => ({
  type: types.HOMEAUDIT_EDIT_SET,
  open,
});

export const getSummary = () => ({
  type: types.HOMEAUDIT_SUMMARY_FETCH,
});

export const setSummaryError = (error) => ({
  type: types.HOMEAUDIT_SUMMARY_SET,
  error,
});

export const setSummary = (summary) => ({
  type: types.HOMEAUDIT_SUMMARY_SET,
  summary,
});

export const setSummaryLoading = (loading) => ({
  type: types.HOMEAUDIT_SUMMARY_LOADING,
  loading,
});
