export const STORE_NAME = 'LeadCapture';

export const POST_LEAD_CAPTURE_REQUEST = 'POST_LEAD_CAPTURE_REQUEST';
export const POST_LEAD_CAPTURE_FAILURE = 'POST_LEAD_CAPTURE_FAILURE';
export const POST_LEAD_CAPTURE_SUCCESS = 'POST_LEAD_CAPTURE_SUCCESS';

export const UPDATE_FORM = 'UPDATE_FORM';
export const RESET_FORM = 'RESET_FORM';

export const SET_LEAD_CAPTURE_DIALOG = 'SET_LEAD_CAPTURE_DIALOG';

export const FIELD_NAME_FIRST = 'leadCaptureNameFirst';
export const FIELD_NAME_LAST = 'leadCaptureNameLast';
export const FIELD_CONTACT = 'leadCaptureContact';
export const FIELD_CONFIRM = 'leadCaptureConfirmed';

export const FIELDS = {
  FIELD_NAME_FIRST: FIELD_NAME_FIRST,
  FIELD_NAME_LAST: FIELD_NAME_LAST,
  FIELD_CONTACT: FIELD_CONTACT,
  FIELD_CONFIRM: FIELD_CONFIRM,
};

export const LEAD_CAPTURE_ERROR =
  "Sorry there was a problem sharing your friend's details, please try again";
