import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as actions from './actions';
import { POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST } from './constants';
import postRenewalDates from './service/postRenewalDates';
import { MobileContractRenewalAPI } from './api';

export function* combinedSagas() {
  yield takeLatest(
    POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST,
    handlePostRenewalDates
  );
}

export function* handlePostRenewalDates() {
  try {
    const contracts = yield select((store) =>
      MobileContractRenewalAPI.getRenewalContracts(store)
    );
    yield call(postRenewalDates, contracts);
    yield put(actions.requestComplete());
  } catch (error) {
    yield put(actions.requestFailed(error));
  }
}
