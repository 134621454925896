import SpendCapDialog from 'modules/Mobile/SpendCapDialog';
import { getSpendCapDialogState } from 'app/redux/modules/Mobile/api';
import { connector } from 'app/redux/utils/connector';
import { STORE_NAME } from '../constants';
import {
  closeSpendCapDialog,
  submitSpendCapDialog,
  updateSpendCapSelected,
} from 'app/redux/modules/Mobile/actions';

const actions = {
  onClose: closeSpendCapDialog,
  setSpendCapSelected: updateSpendCapSelected,
  submit: submitSpendCapDialog,
};

export default connector(
  STORE_NAME,
  getSpendCapDialogState,
  actions
)(SpendCapDialog);
