import Immutable from 'immutable';

import {
  POST_LEAD_CAPTURE_FAILURE,
  POST_LEAD_CAPTURE_REQUEST,
  POST_LEAD_CAPTURE_SUCCESS,
  UPDATE_FORM,
  RESET_FORM,
} from './constants';

import {
  FIELD_NAME_FIRST,
  FIELD_NAME_LAST,
  FIELD_CONTACT,
  FIELD_CONFIRM,
  SET_LEAD_CAPTURE_DIALOG,
} from './constants';

const initialState = Immutable.fromJS({
  open: false,
  loading: false,
  success: false,
  error: null,
  form: {
    [FIELD_NAME_FIRST]: null,
    [FIELD_NAME_LAST]: null,
    [FIELD_CONTACT]: null,
    [FIELD_CONFIRM]: false,
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case POST_LEAD_CAPTURE_REQUEST: {
      return state.merge({
        loading: true,
        success: false,
        error: null,
      });
    }

    case POST_LEAD_CAPTURE_SUCCESS: {
      return state.merge({
        loading: false,
        success: true,
        error: null,
      });
    }
    case POST_LEAD_CAPTURE_FAILURE: {
      return state.merge({
        loading: false,
        error: action.error,
      });
    }
    case UPDATE_FORM: {
      const { field, value } = action;
      return state.setIn(['form', field], value);
    }
    case RESET_FORM: {
      return state.merge({
        form: initialState.get('form'),
        success: false,
        loading: false,
      });
    }
    case SET_LEAD_CAPTURE_DIALOG: {
      return state.setIn(['open'], action.open);
    }

    default:
      return state;
  }
}
