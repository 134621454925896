import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import {
  UPDATE_HOME_QUOTE,
  GET_HOME_INSURANCE_QUOTE_REQUEST,
} from './constants';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { ApplicationApi } from 'redux/modules/App/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { submitProviderDetails } from 'redux/modules/HomeAudit/actions';
import { SERVICES } from 'redux/modules/HomeAudit/constants';

import {
  getHomeCoverQuote,
  updateHomeCoverQuote,
  acceptHomeCoverQuote,
} from './service';
import * as actions from './actions';
import { HomeCoverApi } from './api';

export function* handleGetHomeCoverQuote() {
  const isRemoteSession = yield select(ApplicationApi.isRemoteSession);
  const mainPeer = yield select(RemoteSessionApi.getMainPeer);
  const actor = yield select(RemoteSessionApi.getActor);

  if (isRemoteSession && mainPeer !== actor.type) {
    return;
  }

  try {
    // yield put(actions.getHomeInsuranceQuoteRequest());
    const { data: homeQuote } = yield call(getHomeCoverQuote);
    yield put(actions.getHomeInsuranceQuoteSuccess(homeQuote));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_HOME_COVER_QUOTE);
    yield put(actions.getHomeInsuranceQuoteFailure(error));
  }
}

export function* handleUpdateHomeCoverQuote() {
  try {
    const quote = yield select(HomeCoverApi.getQuote);

    // yield put(actions.updateHomeInsuranceQuoteRequest());
    yield call(updateHomeCoverQuote, quote.quoteId, quote.requirements);
    // yield put(actions.updateHomeInsuranceQuoteSuccess());
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_HOME_COVER_QUOTE);
    yield put(actions.updateHomeInsuranceQuoteFailure(error));
    const errorMessage = `We encountered an error while updating your quote. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* handleAcceptHomeCoverQuote() {
  try {
    const quote = yield select(HomeCoverApi.getQuote);
    const error = yield select(HomeCoverApi.getErrorGetQuote);

    if (quote.id) {
      yield put(actions.acceptHomeInsuranceQuoteRequest());
      yield call(acceptHomeCoverQuote, quote.id);
      yield put(actions.acceptHomeInsuranceQuoteSuccess());
      return yield put(submitProviderDetails(SERVICES.HOME_INSURANCE));
    }
    // don't have a quote
    // Retry if quote is still loading when customer clicks accept
    if (!error) {
      yield delay(2000);
      return yield put(actions.acceptHomeInsuranceQuoteRequest());
    }
    // handle error
    yield put(actions.acceptHomeInsuranceQuoteFailure('Missing quote id'));
    const errorMessage = 'We encountered an error while accepting your quote.';
    yield put(openErrorGlobalDialog(errorMessage));
  } catch (error) {
    Sentry.log(error, ERRORS.ACCEPT_HOME_COVER_QUOTE);
    yield put(actions.acceptHomeInsuranceQuoteFailure(error));
    const errorMessage = `We encountered an error while accepting your quote. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* combinedSagas() {
  yield takeLatest(UPDATE_HOME_QUOTE, handleUpdateHomeCoverQuote);
  yield takeLatest(GET_HOME_INSURANCE_QUOTE_REQUEST, handleGetHomeCoverQuote);
}
