import { API_URL } from 'config';

import axios from 'axios';

export default function(data) {
  let url = `${API_URL}/application/products/mobile/codes/validate`;
  const inst = axios.create();
  const config = {
    url,
    method: 'post',
    data,
  };

  return inst.request(config);
}
