export const STORE_NAME = 'MobileRenewal';

export const UPDATE_MOBILE_RENEWAL_FIELD = 'UPDATE_MOBILE_RENEWAL_FIELD';
export const POST_MOBILE_RENEWAL_DATE_SUCCESS =
  'POST_MOBILE_RENEWAL_DATE_SUCCESS';
export const POST_MOBILE_RENEWAL_DATE_REQUEST =
  'POST_MOBILE_RENEWAL_DATE_REQUEST';
export const POST_MOBILE_RENEWAL_DATE_FAILURE =
  'POST_MOBILE_RENEWAL_DATE_FAILURE';
export const VALIDATE_MOBILE_RENEWAL = 'VALIDATE_MOBILE_RENEWAL';
