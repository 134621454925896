import { takeLatest, put, select, call, fork } from 'redux-saga/effects';
import * as types from './types';
import * as actions from './actions';
import * as HomeAuditApi from './api';
import {
  LABEL_AMOUNT,
  LABEL_PROVIDER,
  LABEL_DATE,
  LABEL_DATE_IGNORE,
  COMBINED_MAP,
  PROVIDERS,
  SERVICES,
} from './constants';
import { getAmountErrors, validateFields } from './validate';
import postSavings from './services/postSavings';
import getSavings from './services/getSavings';
import { notificationKeys } from 'redux/modules/Notifications/library';
import { addNotification } from 'redux/modules/Notifications/actions';

function* fetchProviders({ service }) {
  yield put(actions.setProviders(service, PROVIDERS[service]));
}

function* submitProviderDetails({ service }) {
  const isServiceMultiple = COMBINED_MAP[service];
  const combinedServices = yield select(HomeAuditApi.getCombinedServices);

  let formDetails = [];

  // combined service (e.g. energy = gas+elec) is selected, and service is not
  // combined under one provider
  if (isServiceMultiple && !combinedServices) {
    for (const s of isServiceMultiple) {
      const fieldValues = yield select((state) =>
        HomeAuditApi.getProviderValues(state, s)
      );
      formDetails.push({ ...fieldValues, service: s });
    }
  } else {
    const fields = yield select((state) =>
      HomeAuditApi.getProviderValues(state, service)
    );

    formDetails = [
      {
        ...fields,
        service,
      },
    ];
  }

  const amountsValid = formDetails.every(
    ({ [LABEL_AMOUNT]: amount }) => !getAmountErrors(amount)
  );

  if (!formDetails.every(validateFields)) {
    return;
  }

  if (!amountsValid) {
    yield put(
      addNotification({
        message: notificationKeys.HOMEAUDIT_VALIDATION_ERROR,
        variant: 'error',
      })
    );
    return;
  }

  // handle removing services depending on combined state
  // happens after input validation to allow sending `null` values
  // in order to clear amounts
  if (isServiceMultiple && !combinedServices) {
    // force combined gas/elec to have an amount of null if selecting
    // non-combined services. This is to ensure no confusion as to which
    // service to return in the summary
    formDetails.push({
      service: SERVICES.GAS_ELEC,
      [LABEL_AMOUNT]: null,
      [LABEL_PROVIDER]: null,
    });
  } else {
    formDetails.push({
      service: SERVICES.GAS,
      [LABEL_AMOUNT]: null,
      [LABEL_PROVIDER]: null,
    });

    formDetails.push({
      service: SERVICES.ELECTRICITY,
      [LABEL_AMOUNT]: null,
      [LABEL_PROVIDER]: null,
    });
  }

  try {
    yield call(
      postSavings,
      formDetails.map((fields) => ({
        type: fields.service,
        monthlySpend: Math.round(Number(fields[LABEL_AMOUNT]) * 100),
        supplier: fields[LABEL_PROVIDER],
        contractEndDate:
          fields[LABEL_DATE_IGNORE] !== true && fields[LABEL_DATE]
            ? fields[LABEL_DATE]
            : undefined,
      }))
    );
  } catch (err) {
    yield put(
      addNotification({
        message: notificationKeys.HOMEAUDIT_SUBMIT_ERROR,
        variant: 'error',
      })
    );
  }
}

function* updateValues({ savings }) {
  const formState = savings.reduce((acc, val) => {
    return {
      ...acc,
      [val.type]: {
        [LABEL_AMOUNT]: val[LABEL_AMOUNT],
        [LABEL_PROVIDER]: val[LABEL_PROVIDER],
      },
    };
  }, {});

  yield put(actions.setFields(formState));
  yield put(actions.setEditDialog(false));

  const amountsValid = savings.every(
    ({ [LABEL_AMOUNT]: amount }) => !getAmountErrors(amount)
  );

  if (!amountsValid) {
    yield put(
      addNotification({
        message: notificationKeys.HOMEAUDIT_VALIDATION_ERROR,
        variant: 'error',
      })
    );
    return;
  }

  try {
    yield call(
      postSavings,
      savings
        .filter(({ [LABEL_AMOUNT]: amount }) => Boolean(amount))
        .map((value) => ({
          type: value.type,
          monthlySpend: Math.round(Number(value[LABEL_AMOUNT]) * 100),
          supplier: value[LABEL_PROVIDER],
        }))
    );

    yield put(
      addNotification({
        message: notificationKeys.HOMEAUDIT_UPDATE_SUCCESS,
        variant: 'success',
      })
    );
  } catch (err) {
    yield put(
      addNotification({
        message: notificationKeys.HOMEAUDIT_SUBMIT_ERROR,
        variant: 'error',
      })
    );
  }

  yield fork(getSummary);
}

export function* removeService({ service }) {
  yield call(postSavings, [
    {
      type: service,
      monthlySpend: null,
      supplier: null,
    },
  ]);
}

function* getSummary() {
  let summary;
  const currentServiceList = yield select(HomeAuditApi.getCurrentServicesList);
  const fields = yield select(HomeAuditApi.getFieldValues);

  const currentCostsComplete = currentServiceList.every((service) => {
    return !!fields[service][LABEL_AMOUNT];
  });

  yield put(actions.setSummaryLoading(true));
  try {
    const response = yield call(getSavings);
    summary = response.data;
  } catch (err) {
    yield put(actions.setSummaryError());
    return;
  }

  const { services } = summary.breakdown;
  const currentCostsTotal = currentCostsComplete
    ? services.reduce((acc, service) => {
        const currentVal = service?.currentMonthlySpend;
        if (!currentVal) return acc;
        if (!acc) {
          return currentVal;
        }

        return {
          ...acc,
          value: acc.value + currentVal?.value,
        };
      }, null)
    : null;

  const monthlyCost = summary.breakdown.promoMonthlyCost
    ? summary.breakdown.promoMonthlyCost?.value
    : summary.breakdown.totalMonthlyCost?.value;

  yield put(
    actions.setSummary({
      ...summary,
      breakdown: {
        ...summary.breakdown,
        currentCostsTotal,
        costDifference: {
          ...currentCostsTotal,
          value: currentCostsTotal?.value - monthlyCost,
        },
      },
    })
  );
}

function* handleEditDialog({ open }) {
  if (open) {
    yield fork(getSummary);
  }
}

export function* combinedSagas() {
  yield takeLatest(types.HOMEAUDIT_PROVIDERS_FETCH, fetchProviders);
  yield takeLatest(types.HOMEAUDIT_DETAILS_SUBMIT, submitProviderDetails);
  yield takeLatest(types.HOMEAUDIT_UPDATE, updateValues);
  yield takeLatest(types.HOMEAUDIT_SUMMARY_FETCH, getSummary);
  yield takeLatest(types.HOMEAUDIT_EDIT_SET, handleEditDialog);
}
