import Immutable from 'immutable';
import { fromJS } from 'immutable';

// Specified Items

export function updateSpecifiedItem(state, payload) {
  const {
    requirementType: updateType,
    itemIndex: updateIndex,
    newItem,
  } = payload;
  const updateReqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === updateType);
  if (updateReqIndex !== -1) {
    return state.updateIn(
      [
        'quote',
        'requirements',
        updateReqIndex,
        'requirement',
        'specifiedItemsList',
        updateIndex,
      ],
      () => fromJS(newItem)
    );
  }
  return state;
}

export function addSpecifiedItem(state, payload) {
  const { requirementType, newItem } = payload;
  const reqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === requirementType);

  if (reqIndex !== -1) {
    return state.updateIn(
      ['quote', 'requirements', reqIndex, 'requirement', 'specifiedItemsList'],
      (list) => list.push(fromJS(newItem))
    );
  }
  return state;
}

export function removeSpecifiedItem(state, payload) {
  const { requirementType: removeType, itemIndex: removeIndex } = payload;
  const removeReqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === removeType);
  if (removeReqIndex !== -1) {
    return state.updateIn(
      [
        'quote',
        'requirements',
        removeReqIndex,
        'requirement',
        'specifiedItemsList',
      ],
      (list) => list.delete(removeIndex)
    );
  }
  return state;
}

// Bicycles

export function updateBicycleItem(state, payload) {
  const {
    requirementType: updateType,
    itemIndex: updateIndex,
    newItem,
  } = payload;
  const updateReqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === updateType);
  if (updateReqIndex !== -1) {
    return state.updateIn(
      [
        'quote',
        'requirements',
        updateReqIndex,
        'requirement',
        'bicyclesList',
        updateIndex,
      ],
      () => fromJS(newItem)
    );
  }
  return state;
}

export function addBicycleItem(state, payload) {
  const { requirementType, newItem } = payload;
  const reqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === requirementType);

  if (reqIndex !== -1) {
    return state.updateIn(
      ['quote', 'requirements', reqIndex, 'requirement', 'bicyclesList'],
      (list) => list.push(fromJS(newItem))
    );
  }
  return state;
}

export function removeBicycleItem(state, payload) {
  const { requirementType: removeType, itemIndex: removeIndex } = payload;
  const removeReqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === removeType);
  if (removeReqIndex !== -1) {
    return state.updateIn(
      ['quote', 'requirements', removeReqIndex, 'requirement', 'bicyclesList'],
      (list) => list.delete(removeIndex)
    );
  }
  return state;
}

// Claims
export function updateClaim(state, payload) {
  const {
    requirementType: updateType,
    itemIndex: updateIndex,
    newItem,
  } = payload;
  const updateReqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === updateType);
  if (updateReqIndex !== -1) {
    return state.updateIn(
      [
        'quote',
        'requirements',
        updateReqIndex,
        'requirement',
        'claimsList',
        updateIndex,
      ],
      () => fromJS(newItem)
    );
  }
  return state;
}

export function addClaim(state, payload) {
  const { requirementType, newItem } = payload;
  const reqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === requirementType);

  if (reqIndex !== -1) {
    return state.updateIn(
      ['quote', 'requirements', reqIndex, 'requirement', 'claimsList'],
      (list) => list.push(fromJS(newItem))
    );
  }
  return state;
}

export function removeClaim(state, payload) {
  const { requirementType: removeType, itemIndex: removeIndex } = payload;
  const removeReqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === removeType);
  if (removeReqIndex !== -1) {
    return state.updateIn(
      ['quote', 'requirements', removeReqIndex, 'requirement', 'claimsList'],
      (list) => list.delete(removeIndex)
    );
  }
  return state;
}

// Requirement

export function updateRequirementField(state, payload) {
  const { requirementType, updatePath, updateValue } = payload;
  const reqIndex = state
    .getIn(['quote', 'requirements'])
    .findIndex((req) => req.get('requirementType') === requirementType);

  if (reqIndex !== -1 && updatePath) {
    return state.updateIn(
      ['quote', 'requirements', reqIndex, 'requirement'].concat(updatePath),
      () => Immutable.fromJS(updateValue)
    );
  } else {
    return state;
  }
}
