import Immutable from 'immutable';
import * as types from './types';
import { SERVICES } from './constants';

export const initialState = Immutable.fromJS({
  providers: {
    [SERVICES.GAS]: [],
    [SERVICES.ELECTRICITY]: [],
    [SERVICES.BROADBAND]: [],
    [SERVICES.MOBILE]: [],
    [SERVICES.INSURANCE]: [],
    [SERVICES.GAS_ELEC]: [],
  },
  fields: {
    [SERVICES.GAS]: {},
    [SERVICES.ELECTRICITY]: {},
    [SERVICES.BROADBAND]: {},
    [SERVICES.MOBILE]: {},
    [SERVICES.INSURANCE]: {},
    [SERVICES.GAS_ELEC]: {},
  },
  combinedServices: true,
  editDialogOpen: false,
  summary: {
    displaySavings: false,
  },
  summaryLoading: false,
});

export default function homeAuditReducer(state = initialState, action) {
  switch (action.type) {
    case types.HOMEAUDIT_PROVIDERS_SET:
      return state.setIn(['providers', action.service], action.providers);
    case types.HOMEAUDIT_FIELD_VALUE_SET:
      return state.setIn(
        ['fields', action.service, action.label],
        action.value
      );
    case types.HOMEAUDIT_COMBINED_SET:
      return state.set('combinedServices', action.combined);
    case types.HOMEAUDIT_FIELDS_SET:
      return state.mergeDeep({
        fields: action.fields,
      });

    case types.HOMEAUDIT_EDIT_SET:
      return state.set('editDialogOpen', action.open);
    case types.HOMEAUDIT_SUMMARY_ERROR:
      return state.set('summaryLoading', false);
    case types.HOMEAUDIT_SUMMARY_SET:
      return state.merge({
        summary: action.summary,
        summaryLoading: false,
      });
    case types.HOMEAUDIT_SUMMARY_LOADING:
      return state.set('summaryLoading', action.loading);
    default:
      return state;
  }
}
