import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { connector } from 'redux/utils/connector';
import * as actionCreators from 'redux/modules';
import BackButtonUI from 'components/modules/Shared/NavigationButton/Back';
import SaveButtonUI from 'components/modules/Shared/NavigationButton/Save';
import { STORE_NAME } from '../App/constants';
import { ApplicationApi } from '../App/api';
import { FeatureFlagsApi } from '../FeatureFlags/api';
import { openGlobalDialog } from '../GlobalDialog/actions';
import { FLAGS } from '../FeatureFlags/constants';

export const BackButtonNavigationContainer = connect(
  () => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        ...actionCreators.BackButtonNavigation,
      },
      dispatch
    )
)(BackButtonUI);

export const SaveButtonNavigationContainer = connector(
  STORE_NAME,
  (state) => ({
    applicationId: ApplicationApi.getCurrentApplication(state),
    visible:
      FeatureFlagsApi.getFlag(state, FLAGS.STATE_RESTORE) &&
      !ApplicationApi.isRemoteSession(state),
  }),
  {
    openGlobalDialog,
  }
)(SaveButtonUI);
