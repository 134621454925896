import { call, put, select, takeLatest } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import getApplicationMobileTariffs from './service/getApplicationMobileTariffs';
import * as types from './types';
import * as actions from './actions';
import putApplicationProductsMobile from './service/putApplicationProductsMobile';
import {
  getNumberOptionsDialogState,
  getSelectedMobileProducts,
  getSelectedTariff,
} from './api';
import {
  formatCodeValidationResponse,
  formatValues,
  getCodeValidationBody,
  validateExistingNumber,
  validateForm,
} from './validation';
import postValidateCodes from './service/postValidateCodes';
import {
  codeErrors,
  defaultTariffError,
} from 'app/redux/modules/Mobile/constants';
import { getClient } from 'app/lib/analytics';
import { EVENTS } from 'app/lib/analytics/constants';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';

export function* handleGetApplicationMobileTariffs() {
  try {
    const { data } = yield call(getApplicationMobileTariffs);
    yield put(actions.getTariffsSuccess(data));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_MOBILE_TARIFFS);
    const message = `${defaultTariffError} ${error}`;
    yield put(actions.getTariffsError(message));
    yield put(openErrorGlobalDialog(message));
  }
}

export const mapMobile = (tariff, selectedProduct) => {
  const {
    options: {
      values: {
        budgetControl,
        keepMyNumber,
        existingNumber,
        pacCode,
        stacCode,
      },
    },
  } = selectedProduct;

  return {
    tariff: tariff.label,
    budgetControl,
    ...(keepMyNumber
      ? {
          phoneNumber: existingNumber,
          pacCode: pacCode || undefined,
        }
      : { stacCode: stacCode || undefined }),
    tariffType: tariff.type,
    //TODO - Remove, requires sorting on backend
    dataAddons: 0,
  };
};

export function* handlePutApplicationProductsMobile() {
  try {
    const { client: analytics } = getClient();
    const mobileOptionsSelectedState = yield select(
      analyticsSelectors[EVENTS.MOBILE_QUOTE_ACCEPTED]
    );
    analytics.track(EVENTS.MOBILE_QUOTE_ACCEPTED, mobileOptionsSelectedState);
    const selected = yield select(getSelectedMobileProducts);
    const tariff = yield select(getSelectedTariff);
    yield call(
      putApplicationProductsMobile,
      selected.map(mapMobile.bind(null, tariff))
    );
    yield put(actions.putMobileSelectionSuccess());
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_MOBILE_PRODUCTS);
    const message = `Sorry we encountered an error while saving your mobile selection. ${error}`;
    yield put(openErrorGlobalDialog(message));
  }
}

export function* validateOptionsForm(values) {
  const existingNumberError = validateExistingNumber(values);
  const errors = { existingNumber: existingNumberError };

  const codeValidationBody = getCodeValidationBody(values);
  if (!codeValidationBody) {
    return errors;
  }

  try {
    const {
      data: [response],
    } = yield call(postValidateCodes, [codeValidationBody]);
    return {
      ...errors,
      ...formatCodeValidationResponse(response),
    };
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_MOBILE_CODE_VALIDATION);
    return {
      ...errors,
      [codeValidationBody.type]: codeErrors[codeValidationBody.type],
    };
  }
}

export function* validateOptionsFormUpdate({ field, value }) {
  const numberDialogState = yield select(getNumberOptionsDialogState);
  const updatedValues = { ...numberDialogState.values, [field]: value };
  const errors = yield call(validateOptionsForm, updatedValues);
  yield put(actions.updateNumberOptionsDialogErrors(errors));
}

export function* validateOptionsFormSubmission() {
  const { values, productId } = yield select(getNumberOptionsDialogState);
  const errors = yield call(validateOptionsForm, values);
  const formValid = validateForm(values, errors);
  if (!formValid) {
    return yield put(actions.updateNumberOptionsDialogErrors(errors));
  }
  yield put(actions.submitNumberOptionsDialog(productId, formatValues(values)));
}

export function* combinedSagas() {
  yield takeLatest(
    types.GET_TARIFFS_REQUEST,
    handleGetApplicationMobileTariffs
  );
  yield takeLatest(
    types.PUT_MOBILE_SELECTION_REQUEST,
    handlePutApplicationProductsMobile
  );
  yield takeLatest(
    types.UPDATE_NUMBER_OPTIONS_DIALOG_FIELD,
    validateOptionsFormUpdate
  );
  yield takeLatest(
    types.VALIDATE_AND_SUBMIT_NUMBER_OPTIONS_DIALOG,
    validateOptionsFormSubmission
  );
}
