import * as types from './types';

export const addNotification = (notification) => ({
  type: types.NOTIFICATION_ADD,
  notification,
});

export const removeNotification = (id) => ({
  type: types.NOTIFICATION_REMOVE,
  id,
});

export const setPersistentNotification = (notification) => ({
  type: types.NOTIFICATION_PERSIST_SET,
  notification,
});
