export const HOMEAUDIT_PROVIDERS_FETCH = 'HOMEAUDIT_PROVIDERS_FETCH';
export const HOMEAUDIT_PROVIDERS_SET = 'HOMEAUDIT_PROVIDERS_SET';
export const HOMEAUDIT_FIELD_VALUE_SET = 'HOMEAUDIT_FIELD_VALUE_SET';
export const HOMEAUDIT_DETAILS_SUBMIT = 'HOMEAUDIT_DETAILS_SUBMIT';

export const HOMEAUDIT_COMBINED_SET = 'HOMEAUDIT_COMBINED_SET';
export const HOMEAUDIT_UPDATE = 'HOMEAUDIT_UPDATE';
export const HOMEAUDIT_FIELDS_SET = 'HOMEAUDIT_FIELDS_SET';

export const HOMEAUDIT_EDIT_SET = 'HOMEAUDIT_EDIT_SET';

export const HOMEAUDIT_SUMMARY_LOADING = 'HOMEAUDIT_SUMMARY_LOADING';
export const HOMEAUDIT_SUMMARY_FETCH = 'HOMEAUDIT_SUMMARY_FETCH';
export const HOMEAUDIT_SUMMARY_ERROR = 'HOMEAUDIT_SUMMARY_ERROR';
export const HOMEAUDIT_SUMMARY_SET = 'HOMEAUDIT_SUMMARY_SET';
