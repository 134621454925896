import {
  authenticatedGet,
  authenticatedPut,
  authenticatedPost,
} from 'redux/utils/request';

export const getHomeCoverQuote = () => authenticatedGet('quotes/homeInsurance');

export const acceptHomeCoverQuote = (quoteId) =>
  authenticatedPut('quotes/homeInsurance/confirm', { quoteId });

export const updateHomeCoverQuote = (quoteId, requirements) =>
  authenticatedPost('quotes/homeInsurance', { quoteId, requirements });
