import { STORE_NAME } from './constants';
import { notificationMap } from './library';

function getMessage(key, customMessage) {
  if (!key) {
    throw new TypeError(
      `valid notification key is required, received "${key}"`
    );
  }
  const message = notificationMap[key];
  if (!message && !customMessage) {
    throw new TypeError(`Notification ${key} not found in library`);
  }

  return message || customMessage;
}

export const getNotifications = (state) =>
  state
    .get(STORE_NAME)
    .get('messages')
    .toJS()
    .map((m) => ({
      ...m,
      message: getMessage(m.message, m.customMessage),
    }));

export const getPersistentNotification = (state) => {
  const persistentNotification = state.get(STORE_NAME).get('persistentMessage');
  if (!persistentNotification || !persistentNotification.message) {
    return null;
  }
  return {
    ...persistentNotification,
    message: getMessage(persistentNotification.message),
  };
};
