import { connector } from 'redux/utils/connector';

import Mobile from 'components/modules/Mobile';
import { getIsProductSelected } from 'redux/modules/Bundles/api';
import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_MOBILE_2,
} from 'redux/modules/Bundles/constants';
import applicationCheck from 'redux/modules/ApplicationCheck';
import {
  getMobileTariffs,
  getSecondMobileDialogOpen,
  getSelectedMobileProducts,
  getSelectedTariff,
} from 'app/redux/modules/Mobile/api';

import * as actions from '../actions';
import { STORE_NAME } from '../constants';
import { multiSimDeals } from 'app/redux/modules/Mobile/constants';

const sumPrices = (products, tariff) => {
  if (!tariff) return;

  const values = products.map(({ discounted }) =>
    discounted ? tariff.discountedPrice.value : tariff.price.value
  );

  return values.reduce(
    (acc, value) => ({
      ...acc,
      value: acc.value + value,
    }),
    {
      currency: 'GBP',
      exponent: 2,
      value: 0,
    }
  );
};

const mapStateToProps = (state) => {
  let selectedProducts = getSelectedMobileProducts(state);
  const selectedTariff = getSelectedTariff(state);
  const multiSimDeal = multiSimDeals[selectedTariff?.label];
  if (multiSimDeal) {
    selectedProducts = selectedProducts.map((product, index) => ({
      ...product,
      discounted: multiSimDeal.minimumSimsRequired < index + 1,
    }));
  }

  const secondMobileDialogOpen = getSecondMobileDialogOpen(state);
  const secondMobileSelected = getIsProductSelected(state, PRODUCT_MOBILE_2);
  const energySelected = getIsProductSelected(state, PRODUCT_ENERGY);
  const broadbandSelected = getIsProductSelected(state, PRODUCT_BROADBAND);

  return {
    selectedTariff,
    tariffs: getMobileTariffs(state),
    selectedProducts,
    monthlyTotal: sumPrices(selectedProducts, selectedTariff),
    nextSimDiscounted: multiSimDeal
      ? selectedProducts.length >= multiSimDeal.minimumSimsRequired &&
        selectedProducts.length <
          multiSimDeal.minimumSimsRequired + multiSimDeal.maximumSimsDiscounted
      : false,
    secondMobileDialogOpen,
    secondMobileSelected,
    energySelected,
    broadbandSelected,
  };
};

const MobileContainer = connector(STORE_NAME, mapStateToProps, {
  onSelectTariff: actions.setSelectedTariffLabel,
  onContinue: actions.putMobileSelectionRequest,
  openSpendCapDialog: actions.openSpendCapDialog,
  openNumberOptionsDialog: actions.openNumberOptionsDialog,
  removeProduct: actions.removeSelectedMobileProduct,
  addProduct: actions.addMobileProduct,
  getTariffsRequest: actions.getTariffsRequest,
  submitMobilePage: actions.putMobileSelectionRequest,
  openSecondMobileDialog: actions.openSecondMobileDialog,
  closeSecondMobileDialog: actions.closeSecondMobileDialog,
})(Mobile);

export default applicationCheck(MobileContainer);
