import isEmpty from 'lodash/isEmpty';
import { STORE_NAME } from './constants';

export class HomeCoverApi {
  static getQuote(state) {
    const quote = state.getIn([STORE_NAME, 'quote'], null);
    return quote && quote.toJS();
  }

  static getQuoteId(state) {
    return state.getIn([STORE_NAME, 'quote', 'id'], null);
  }

  static getQuoteDocuments(state) {
    return state.getIn([STORE_NAME, 'quote', 'documents'], null).toJS();
  }

  static getErrorGetQuote(state) {
    return state.getIn([STORE_NAME, 'errorGetQuote'], null);
  }

  static isQuoteLoading(state) {
    return state.getIn([STORE_NAME, 'loadingGetQuote'], false);
  }

  static isAcceptQuoteLoading(state) {
    return state.getIn([STORE_NAME, 'loadingAcceptQuote'], false);
  }

  static getQuoteError(state) {
    return state.getIn([STORE_NAME, 'errorGetQuote'], null);
  }

  static canShowQuote(state) {
    return (
      !isEmpty(HomeCoverApi.getQuote(state)) &&
      isEmpty(HomeCoverApi.getQuoteError(state))
    );
  }

  static hasQuote(state) {
    return state.getIn([STORE_NAME, 'quote', 'id'], null) !== null;
  }
}
