import { isEmpty } from 'lodash';
import { LABEL_PROVIDER, LABEL_AMOUNT } from './constants';

export const VALIDATION_ERRORS = {
  ERR_AMOUNT_INVALID: 'Please enter a valid amount',
};

export const getAmountErrors = (amount) => {
  if (!amount) return null;

  const value = String(amount);
  const number = Number(value);
  if (
    !value.match(/^[0-9.]+$/) ||
    value.match(/\.$/) ||
    Number.isNaN(number) ||
    !Number.isFinite(number) ||
    number < 0
  ) {
    return VALIDATION_ERRORS.ERR_AMOUNT_INVALID;
  }

  return null;
};

export const validateFields = (fields) => {
  return !(isEmpty(fields[LABEL_AMOUNT]) && isEmpty(fields[LABEL_PROVIDER]));
};
