import { connect } from 'react-redux';
import { ApplicationApi } from 'redux/modules/App/api';
import { setExclusionParams } from 'redux/modules/App/actions';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { RestoreApi } from 'redux/modules/Restore/api';
import LandingPageComponent from 'pages/LandingPage';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { setServicePreselect } from 'redux/modules/ServiceSelection/actions';

const videoId = '260033583';

export const LandingPage = connect(
  (state) => ({
    videoId,
    newEntryOrganic: FeatureFlagsApi.getFlag(state, FLAGS.LANDING_PAGE_NEW),
    partnerLoggedIn: PartnerLoginApi.isPartnerLoggedIn(state),
    isRemote: ApplicationApi.isRemoteSession(state),
    hasRestoreToken: RestoreApi.hasRestoreToken(state),
  }),
  {
    setServicePreselect,
    setExclusionParams,
  }
)(LandingPageComponent);
