import { connector } from 'redux/utils/connector';
import { HomeCoverApi } from 'redux/modules/HomeInsurance/api';
import {
  updateQuoteField,
  addSpecifiedItem,
  updateSpecifiedItem,
  removeSpecifiedItem,
  addBicycleItem,
  updateBicycleItem,
  removeBicycleItem,
  updateHomeQuote,
  getHomeInsuranceQuoteRequest,
  updateClaim,
  addClaim,
  removeClaim,
} from 'redux/modules/HomeInsurance/actions';
import { STORE_NAME } from 'redux/modules/HomeInsurance/constants';
import HomeInsurance from 'components/modules/HomeInsurance';

export default connector(
  STORE_NAME,
  (state) => ({
    quote: HomeCoverApi.getQuote(state),
  }),
  {
    updateQuoteField,
    addSpecifiedItem,
    updateSpecifiedItem,
    removeSpecifiedItem,
    addBicycleItem,
    updateBicycleItem,
    removeBicycleItem,
    getHomeInsuranceQuoteRequest,
    updateHomeQuote,
    updateClaim,
    addClaim,
    removeClaim,
  }
)(HomeInsurance);
