import { STORE_NAME } from './constants';

export const getFormFields = (state) => {
  return state.getIn([STORE_NAME, 'form']).toJS();
};

export const getFormSuccess = (state) => {
  return state.getIn([STORE_NAME, 'success']);
};

export const getFormError = (state) => {
  return state.getIn([STORE_NAME, 'error']);
};

export const getIsLeadCaptureDialogOpen = (state) => {
  return state.getIn([STORE_NAME, 'open']);
};
