import { jtcRequest } from 'redux/utils/request';

export default (applicationId, partnerId, applicationToken, prospect) =>
  jtcRequest(
    'POST',
    `application/${applicationId}/leads/raf`,
    {
      prospect,
    },
    {
      applicationToken,
      positionId: partnerId,
    }
  );
