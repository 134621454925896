import { mockQuote } from 'app/components/modules/HomeInsurance/testConts';
import Immutable from 'immutable';

export const STORE_NAME = 'HomeCover';

// Actions

export const UPDATE_HOME_INSURANCE_VALUE = 'UPDATE_HOME_INSURANCE_VALUE';
export const UPDATE_HOME_INSURANCE_QUOTE_REQUEST =
  'UPDATE_HOME_INSURANCE_REQUEST';
export const UPDATE_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS =
  'UPDATE_HOME_INSURANCE_REQUEST_SUCCESS';
export const UPDATE_HOME_INSURANCE_QUOTE_REQUEST_FAILURE =
  'UPDATE_HOME_INSURANCE_QUOTE_REQUEST_FAILURE';
export const GET_HOME_INSURANCE_QUOTE_REQUEST =
  'GET_HOME_INSURANCE_QUOTE_REQUEST';
export const GET_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS =
  'GET_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS';
export const GET_HOME_INSURANCE_QUOTE_REQUEST_FAILURE =
  'GET_HOME_INSURANCE_QUOTE_REQUEST_FAILURE';
export const ACCEPT_HOME_INSURANCE_QUOTE_REQUEST =
  'ACCEPT_HOME_INSURANCE_QUOTE_REQUEST';
export const ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS =
  'ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS';
export const ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_FAILURE =
  'ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_FAILURE';
export const UPDATE_QUOTE_FIELD = 'UPDATE_QUOTE_FIELD';
export const UPDATE_SPECIFIED_ITEM = 'UPDATE_SPECIFIED_ITEM';
export const ADD_SPECIFIED_ITEM = 'ADD_SPECIFIED_ITEM';
export const REMOVE_SPECIFIED_ITEM = 'REMOVE_SPECIFIED_ITEM';
export const UPDATE_BICYCLE_ITEM = 'UPDATE_BICYCLE_ITEM';
export const ADD_BICYCLE_ITEM = 'ADD_BICYCLE_ITEM';
export const REMOVE_BICYCLE_ITEM = 'REMOVE_BICYCLE_ITEM';
export const UPDATE_HOME_QUOTE = 'UPDATE_HOME_QUOTE';
export const UPDATE_CLAIMS_ITEM = 'UPDATE_CLAIMS_ITEM';
export const ADD_CLAIMS_ITEM = 'ADD_CLAIMS_ITEM';
export const REMOVE_CLAIMS_ITEM = 'REMOVE_CLAIMS_ITEM';

// Initial state

export const initialState = Immutable.fromJS({
  quote: mockQuote,
  loadingGetQuote: false,
  errorGetQuote: null,
  loadingAcceptQuote: false,
  errorAcceptQuote: null,
  loadingUpdateQuote: false,
  errorUpdateQuote: null,
});

export const PLACEHOLDER_QUOTE = {
  id: null,
  reference: null,
  primaryPolicyHolder: {
    title: null,
    firstName: null,
    lastName: null,
    email: null,
    dateOfBirth: null,
    maritalStatus: null,
    relationship: null,
    phoneNumber: null,
    employment: {
      fullTimeOccupation: null,
      fullTimeBusiness: null,
      partTimeEmployment: false,
      partTimeOccupation: null,
      partTimeBusiness: null,
    },
  },
  jointPolicyHolder: null,
  policyHolder: null,
  address: {
    postcode: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    line5: '',
    uprn: '',
  },
  householdClaims: null,
  property: {
    details: null,
    construction: {
      dateBuilt: null,
      yearBuilt: null,
      architectureInterestCode: null,
      wallConstruction: null,
      roofConstruction: null,
      flatRoofPercentage: null,
    },
    occupancy: {
      numberAdults: null,
      numberChildren: null,
      smoker: null,
      soleFamily: true,
      let: null,
      boarders: null,
      payingGuests: null,
      unoccupied: null,
      unoccupiedReason: null,
      unoccupiedDays: null,
      businessChildMind: false,
      businessUse: null,
      businessVisitors: null,
    },
    locks: {
      windowLocks: null,
      finalExitDoorType: null,
      patioDoors: false,
      patioDoorsType: null,
      otherDoors: false,
      otherDoorsType: null,
    },
    alarms: null,
  },
  cover: {
    startDate: null,
    type: null,
    buildingClaimFreeYears: null,
    contentsClaimFreeYears: null,
    buildingExcess: 0,
    contentsExcess: 0,
    accidentalDamageBuildings: null,
    accidentalDamageContents: null,
    bicycles: null,
    personalPossessions: null,
    disclosures: {
      details: null,
      criminalConvictions: false,
      unsatisfiedCourtJudgment: false,
      declaredBankrupt: false,
      previousPolicyConditions: false,
      ukResident: null,
      selfContainedProperty: null,
      noSubsidenceLandslip: null,
      noFlooding: null,
    },
  },
  previousProvider: null,
  previousPremium: null,
  firstPayment: null,
  monthlyPremium: null,
  annualPremium: null,
  addons: null,
  documents: [],
  converted: false,
  excesses: null,
  endorsements: null,
  insurer: null,
};
