import {
  STORE_NAME,
  APPOINTMENT_TYPE,
  SUPPORT_SELECTION_OPTIONS,
} from './constants';

export class LandingPageApi {
  static getAppointmentType(state) {
    return state.getIn([STORE_NAME, 'appointmentType']);
  }

  static isRemoteAppointment(state) {
    return (
      state.getIn([STORE_NAME, 'appointmentType']) === APPOINTMENT_TYPE.REMOTE
    );
  }

  static isSupportingRemotelyAppointment(state) {
    return (
      state.getIn([STORE_NAME, 'appointmentType']) ===
      APPOINTMENT_TYPE.REMOTE_SUPPORT
    );
  }

  static getSupportSelection(state) {
    return state.getIn([STORE_NAME, 'support']);
  }

  static isBeingSupportedRemotely(state) {
    return (
      state.getIn([STORE_NAME, 'appointmentType']) ===
        APPOINTMENT_TYPE.FACE_TO_FACE &&
      state.getIn([STORE_NAME, 'support']) === SUPPORT_SELECTION_OPTIONS.remote
    );
  }
}
