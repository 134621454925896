import * as constants from './constants';
import {
  addSpecifiedItem,
  updateSpecifiedItem,
  removeSpecifiedItem,
  addBicycleItem,
  updateBicycleItem,
  removeBicycleItem,
  updateRequirementField,
  addClaim,
  updateClaim,
  removeClaim,
} from './helpers';

export default function(state = constants.initialState, action) {
  switch (action.type) {
    case constants.UPDATE_HOME_INSURANCE_QUOTE_REQUEST:
      return state.merge({ loading: true, error: null });

    case constants.UPDATE_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
      });

    case constants.UPDATE_HOME_INSURANCE_QUOTE_REQUEST_FAILURE:
      return state.merge({ loading: false, error: action.error });

    case constants.GET_HOME_INSURANCE_QUOTE_REQUEST:
      return state.merge({ loadingGetQuote: true, errorGetQuote: null });
    case constants.GET_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS:
      return state.merge({
        loadingGetQuote: false,
        errorGetQuote: null,
        quote: action.quote,
      });
    case constants.GET_HOME_INSURANCE_QUOTE_REQUEST_FAILURE:
      return state.merge({
        loadingGetQuote: false,
        errorGetQuote: action.error,
      });

    case constants.ACCEPT_HOME_INSURANCE_QUOTE_REQUEST:
      return state.merge({
        loadingAcceptQuote: true,
        errorAcceptQuote: null,
        acceptQuoteDialogOpen: false,
      });
    case constants.ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS:
      return state.merge({
        loadingAcceptQuote: false,
        errorAcceptQuote: null,
      });
    case constants.ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_FAILURE:
      return state.merge({
        loadingAcceptQuote: false,
        errorAcceptQuote: action.error,
      });
    case constants.UPDATE_QUOTE_FIELD:
      return updateRequirementField(state, action.payload);
    case constants.UPDATE_SPECIFIED_ITEM:
      return updateSpecifiedItem(state, action.payload);
    case constants.ADD_SPECIFIED_ITEM:
      return addSpecifiedItem(state, action.payload);
    case constants.REMOVE_SPECIFIED_ITEM:
      return removeSpecifiedItem(state, action.payload);
    case constants.UPDATE_BICYCLE_ITEM:
      return updateBicycleItem(state, action.payload);
    case constants.ADD_BICYCLE_ITEM:
      return addBicycleItem(state, action.payload);
    case constants.REMOVE_BICYCLE_ITEM:
      return removeBicycleItem(state, action.payload);
    case constants.UPDATE_CLAIMS_ITEM:
      return updateClaim(state, action.payload);
    case constants.ADD_CLAIMS_ITEM:
      return addClaim(state, action.payload);
    case constants.REMOVE_CLAIMS_ITEM:
      return removeClaim(state, action.payload);
    default:
      return state;
  }
}
