import {
  UPDATE_MOBILE_RENEWAL_FIELD,
  POST_MOBILE_RENEWAL_DATE_REQUEST,
  POST_MOBILE_RENEWAL_DATE_SUCCESS,
  POST_MOBILE_RENEWAL_DATE_FAILURE,
  VALIDATE_MOBILE_RENEWAL,
} from './constants';
import validator from '../../utils/dateValidationFormRenewal';

export const updateField = (date) => ({
  type: UPDATE_MOBILE_RENEWAL_FIELD,
  date,
});
export const submit = (date) => ({
  type: POST_MOBILE_RENEWAL_DATE_REQUEST,
  date,
});
export const requestComplete = (savings) => ({
  type: POST_MOBILE_RENEWAL_DATE_SUCCESS,
  savings,
});
export const requestFailed = (error) => ({
  type: POST_MOBILE_RENEWAL_DATE_FAILURE,
  error,
});
export const validate = (date) => ({
  type: VALIDATE_MOBILE_RENEWAL,
  ...validator(date),
});
