import React from 'react';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export const notificationKeys = {
  REMOTE_CLIENT_JOINED: 'REMOTE_CLIENT_JOINED',

  REMOTE_CLIENT_CONNECTING: 'REMOTE_CLIENT_CONNECTING',
  REMOTE_CLIENT_CONNECTED: 'REMOTE_CLIENT_CONNECTED',
  REMOTE_CLIENT_CUSTOMER_CONNECTED: 'REMOTE_CLIENT_CUSTOMER_CONNECTED',
  REMOTE_CLIENT_SUPPORTING_CONNECTED: 'REMOTE_CLIENT_SUPPORTING_CONNECTED',
  REMOTE_CLIENT_DISCONNECTED: 'REMOTE_CLIENT_DISCONNECTED',
  REMOTE_CLIENT_ENDED: 'REMOTE_CLIENT_ENDED',

  REMOTE_CONTROL_SET_SELF: 'REMOTE_CONTROL_SET_SELF',
  REMOTE_CONTROL_SET_PARTNER: 'REMOTE_CONTROL_SET_PARTNER',
  REMOTE_CONTROL_SET_SUPPORTING: 'REMOTE_CONTROL_SET_SUPPORTING',
  REMOTE_CONTROL_SET_CUSTOMER: 'REMOTE_CONTROL_SET_CUSTOMER',
  REMOTE_PRIVATE: 'REMOTE_PRIVATE',

  HOMEAUDIT_SUBMIT_ERROR: 'HOMEAUDIT_SUBMIT_ERROR',
  HOMEAUDIT_VALIDATION_ERROR: 'HOMEAUDIT_VALIDATION_ERROR',
  HOMEAUDIT_UPDATE_SUCCESS: 'HOMEAUDIT_UPDATE_SUCCESS',

  SUMMARY_REMOVE_PRODUCT_SUCCESS: 'SUMMARY_REMOVE_PRODUCT_SUCCESS',
  SUMMARY_QUOTE_SENT_SUCCESS: 'SUMMARY_QUOTE_SENT_SUCCESS',
  SUMMARY_APPLICATION_SAVE_SUCCESS: 'SUMMARY_APPLICATION_SAVE_SUCCESS',

  DETAILS_EECC_EMAIL_SUBMIT: 'DETAILS_EECC_EMAIL_SUBMIT',
};

export const notificationMap = {
  [notificationKeys.REMOTE_CLIENT_JOINED]: 'You have joined the appointment',

  [notificationKeys.REMOTE_CLIENT_CONNECTING]: 'Connecting...',
  [notificationKeys.REMOTE_CLIENT_CONNECTED]: 'Connected',
  [notificationKeys.REMOTE_CLIENT_CUSTOMER_CONNECTED]:
    'Customer joined the appointment',
  [notificationKeys.REMOTE_CLIENT_SUPPORTING_CONNECTED]:
    'Supporting partner joined the appointment',
  [notificationKeys.REMOTE_CLIENT_DISCONNECTED]: 'Trying to reconnect...',
  [notificationKeys.REMOTE_CLIENT_ENDED]: 'Session ended',

  [notificationKeys.REMOTE_CONTROL_SET_SELF]: 'You have control',
  [notificationKeys.REMOTE_CONTROL_SET_PARTNER]: 'Partner has control',
  [notificationKeys.REMOTE_CONTROL_SET_SUPPORTING]:
    'Supporting partner has control',
  [notificationKeys.REMOTE_CONTROL_SET_CUSTOMER]: 'Customer has control',

  [notificationKeys.REMOTE_PRIVATE]: (
    <>
      <VisibilityOffIcon /> Private view
    </>
  ),

  [notificationKeys.HOMEAUDIT_SUBMIT_ERROR]:
    'Failed to submit current provider details',
  [notificationKeys.HOMEAUDIT_VALIDATION_ERROR]:
    'Current provider details invalid',
  [notificationKeys.HOMEAUDIT_UPDATE_SUCCESS]:
    'Current provider details updated successfully',

  [notificationKeys.SUMMARY_APPLICATION_SAVE_SUCCESS]:
    'Application successfully saved',
  [notificationKeys.DETAILS_EECC_EMAIL_SUBMIT]: 'Contract email sent',
};
