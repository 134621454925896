import { SERVICES } from './constants';

export function getCurrentServiceList({
  serviceFields,
  energyCombined,
  selectedProducts = [],
}) {
  const hasElec = selectedProducts.includes(SERVICES.ELECTRICITY);
  const hasGas = selectedProducts.includes(SERVICES.GAS);
  const dualEnergy = hasElec && hasGas;
  const canCombineEnergy = energyCombined && dualEnergy;

  return Object.keys(serviceFields).filter((service) => {
    if (service === SERVICES.GAS) {
      return (dualEnergy || hasGas) && !canCombineEnergy;
    }

    if (service === SERVICES.ELECTRICITY) {
      return (dualEnergy || hasElec) && !canCombineEnergy;
    }

    if (service === SERVICES.GAS_ELEC) {
      return canCombineEnergy;
    }

    return selectedProducts.includes(service);
  });
}

export function normalizeAmountValue(value) {
  // normalize only valid numbers
  if (Number.isNaN(Number(value)) || String(value).match(/\.$/)) {
    return value;
  }
  const [whole, fraction] = String(value).split('.');
  // allow only two decimals
  return fraction ? [whole, fraction.substr(0, 2)].join('.') : whole;
}
