import Immutable from 'immutable';
import { v4 as uuid } from 'uuid';
import * as types from './types';

const initialState = Immutable.fromJS({
  persistentMessage: null,
  messages: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.NOTIFICATION_ADD: {
      const id = uuid();
      return state.set(
        'messages',
        state.get('messages').push(
          Immutable.Map({
            ...action.notification,
            id,
            autoClose: action.notification.autoClose,
          })
        )
      );
    }
    case types.NOTIFICATION_REMOVE: {
      const { id } = action;
      const messages = state.get('messages').filter((n) => n.get('id') !== id);
      return state.set('messages', messages);
    }
    case types.NOTIFICATION_PERSIST_SET: {
      if (!action.notification) {
        return state.set('persistentMessage', null);
      }

      return state.set('persistentMessage', action.notification);
    }
    default:
      return state;
  }
};
