import { connector } from 'redux/utils/connector';
import NotificationOverlay from 'components/modules/Notifications/Overlay';
import { removeNotification } from './actions';
import { STORE_NAME } from './constants';
import * as NotificationsApi from './api';

const mapStateToProps = (state) => ({
  messages: NotificationsApi.getNotifications(state),
  persistentMessage: NotificationsApi.getPersistentNotification(state),
});

const bindActionCreators = {
  onClose: removeNotification,
};

export default connector(
  STORE_NAME,
  mapStateToProps,
  bindActionCreators
)(NotificationOverlay);
