import { takeLatest, select, put } from 'redux-saga/effects';
import Sentry from 'app/lib/analytics/sentry';

import postCustomerReferralLead from './service/postCustomerReferralLead';
import {
  FIELD_CONTACT,
  FIELD_NAME_FIRST,
  FIELD_NAME_LAST,
  LEAD_CAPTURE_ERROR,
  POST_LEAD_CAPTURE_REQUEST,
} from './constants';
import { ApplicationApi } from '../App/api';
import { PartnerLoginApi } from '../PartnerLogin/api';
import { getFormFields } from './api';
import { requestComplete, requestFailed } from './actions';
import ERRORS from 'app/lib/analytics/sentry/errors';

function* handlePostLeadRequest() {
  const partnerPositionId = yield select(PartnerLoginApi.getPartnerId);
  const remotePartnerId = yield select(ApplicationApi.getRemotePartnerId);
  const applicationId = yield select(ApplicationApi.getId);
  const applicationToken = yield select(ApplicationApi.getToken);
  const fields = yield select(getFormFields);
  const prospect = {
    firstName: fields[FIELD_NAME_FIRST],
    lastName: fields[FIELD_NAME_LAST],
    phoneNumber: fields[FIELD_CONTACT],
  };
  try {
    yield postCustomerReferralLead(
      applicationId,
      partnerPositionId || remotePartnerId,
      applicationToken,
      prospect
    );
    yield put(requestComplete());
  } catch (error) {
    Sentry.log(error, ERRORS.LEAD_CAPTURE);
    yield put(requestFailed(LEAD_CAPTURE_ERROR));
  }
}

export function* combinedSagas() {
  yield takeLatest(POST_LEAD_CAPTURE_REQUEST, handlePostLeadRequest);
}
