import * as constants from './constants';

export const updateHomeInsuranceQuote = (homeInsurance) => ({
  type: constants.UPDATE_HOME_INSURANCE_VALUE,
  homeInsurance,
});

export const updateHomeInsuranceQuoteRequest = () => ({
  type: constants.UPDATE_HOME_INSURANCE_QUOTE_REQUEST,
});

export const updateHomeInsuranceQuoteSuccess = () => ({
  type: constants.UPDATE_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS,
});

export const updateHomeInsuranceQuoteFailure = (error) => ({
  type: constants.UPDATE_HOME_INSURANCE_QUOTE_REQUEST_FAILURE,
  error,
});

// get quote
export const getHomeInsuranceQuoteRequest = () => ({
  type: constants.GET_HOME_INSURANCE_QUOTE_REQUEST,
});

export const getHomeInsuranceQuoteSuccess = (quote) => ({
  type: constants.GET_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS,
  quote,
});

export const getHomeInsuranceQuoteFailure = (error) => ({
  type: constants.GET_HOME_INSURANCE_QUOTE_REQUEST_FAILURE,
  error,
});

// accept quote
export const acceptHomeInsuranceQuoteRequest = () => ({
  type: constants.ACCEPT_HOME_INSURANCE_QUOTE_REQUEST,
});

export const acceptHomeInsuranceQuoteSuccess = () => ({
  type: constants.ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_SUCCESS,
});

export const acceptHomeInsuranceQuoteFailure = (error) => ({
  type: constants.ACCEPT_HOME_INSURANCE_QUOTE_REQUEST_FAILURE,
  error,
});

export const updateQuoteField = (requirementType, updatePath, updateValue) => ({
  type: constants.UPDATE_QUOTE_FIELD,
  payload: { requirementType, updatePath, updateValue },
});

export const updateSpecifiedItem = (requirementType, itemIndex, newItem) => ({
  type: constants.UPDATE_SPECIFIED_ITEM,
  payload: { requirementType, itemIndex, newItem },
});

export const addSpecifiedItem = (requirementType, newItem) => ({
  type: constants.ADD_SPECIFIED_ITEM,
  payload: { requirementType, newItem },
});

export const removeSpecifiedItem = (requirementType, itemIndex) => ({
  type: constants.REMOVE_SPECIFIED_ITEM,
  payload: { requirementType, itemIndex },
});

export const updateBicycleItem = (requirementType, itemIndex, newItem) => ({
  type: constants.UPDATE_BICYCLE_ITEM,
  payload: { requirementType, itemIndex, newItem },
});

export const addBicycleItem = (requirementType, newItem) => ({
  type: constants.ADD_BICYCLE_ITEM,
  payload: { requirementType, newItem },
});

export const removeBicycleItem = (requirementType, itemIndex) => ({
  type: constants.REMOVE_BICYCLE_ITEM,
  payload: { requirementType, itemIndex },
});

export const updateClaim = (requirementType, itemIndex, newItem) => ({
  type: constants.UPDATE_CLAIMS_ITEM,
  payload: { requirementType, itemIndex, newItem },
});

export const addClaim = (requirementType, newItem) => ({
  type: constants.ADD_CLAIMS_ITEM,
  payload: { requirementType, newItem },
});

export const removeClaim = (requirementType, itemIndex) => ({
  type: constants.REMOVE_CLAIMS_ITEM,
  payload: { requirementType, itemIndex },
});

export const updateHomeQuote = () => ({
  type: constants.UPDATE_HOME_QUOTE,
});
