import { validateGBMobileNumber } from 'app/redux/utils/validateGBNumber';
import { codeErrors, codeTypes } from './constants';

export const requiresExistingNumber = ({ keepMyNumber, cancelOldContract }) =>
  keepMyNumber || cancelOldContract;

export const requiresStacCode = ({ keepMyNumber, cancelOldContract }) =>
  cancelOldContract && !requiresPacCode({ keepMyNumber });

export const requiresPacCode = ({ keepMyNumber }) => keepMyNumber;

export const validateForm = (values, errors) => {
  if (
    requiresExistingNumber(values) &&
    (!values.existingNumber || errors.existingNumber)
  ) {
    return false;
  }

  if (requiresStacCode(values) && (!values.stacCode || errors.stacCode)) {
    return false;
  }

  if (requiresPacCode(values) && (!values.pacCode || errors.pacCode)) {
    return false;
  }

  return true;
};

export const sanitiseExistingNumber = (number) => number?.replace(/ /g, '');

export const validateExistingNumber = (values) => {
  if (!requiresExistingNumber(values)) {
    return undefined;
  }

  const sanitisedExistingNumber = sanitiseExistingNumber(values.existingNumber);
  const validExistingNumber =
    !sanitisedExistingNumber || validateGBMobileNumber(sanitisedExistingNumber);

  if (!validExistingNumber) {
    return codeErrors.existingNumber;
  }
};

export const getCodeValidationBody = (values) => {
  const { pacCode, stacCode } = values;
  if (pacCode !== '' && requiresPacCode(values)) {
    return {
      type: codeTypes.PAC,
      code: pacCode,
    };
  } else if (stacCode !== '' && requiresStacCode(values)) {
    return {
      type: codeTypes.STAC,
      code: stacCode,
    };
  }

  return undefined;
};

export const formatCodeValidationResponse = (response) => {
  if (response.valid === true) {
    return {};
  }

  return {
    [`${response.type}Code`]: !response.valid
      ? codeErrors[response.type]
      : undefined,
  };
};

export const formatValues = (values) => {
  const formattedValues = {
    keepMyNumber: values.keepMyNumber,
    cancelOldContract: values.cancelOldContract,
  };

  if (requiresExistingNumber(values)) {
    formattedValues.existingNumber = sanitiseExistingNumber(
      values.existingNumber
    );
  }

  if (requiresStacCode(values)) {
    formattedValues.stacCode = values.stacCode;
  }

  if (requiresPacCode(values)) {
    formattedValues.pacCode = values.pacCode;
  }

  return formattedValues;
};
