import Immutable from 'immutable';
import validator from '../../utils/dateValidationFormRenewal';

import {
  UPDATE_MOBILE_RENEWAL_FIELD,
  POST_MOBILE_RENEWAL_DATE_REQUEST,
  POST_MOBILE_RENEWAL_DATE_SUCCESS,
  POST_MOBILE_RENEWAL_DATE_FAILURE,
  VALIDATE_MOBILE_RENEWAL,
} from './constants';

const initialState = Immutable.fromJS({
  fields: { date: '', error: '' },
  loading: false,
  formComplete: false,
  submitted: false,
  error: null,
});

const mobileRenewalReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MOBILE_RENEWAL_FIELD: {
      const validation = validator(action.date);
      if (action.date.length === 10) {
        return state.merge(validation);
      }
      return state.merge({
        fields: { date: action.date },
        formComplete: validation.formComplete,
      });
    }

    case VALIDATE_MOBILE_RENEWAL: {
      return state.merge({
        formComplete: action.formComplete,
        fields: action.fields,
        error: action.fields.error,
      });
    }

    case POST_MOBILE_RENEWAL_DATE_REQUEST:
      return state.mergeDeep({ loading: true, error: null });

    case POST_MOBILE_RENEWAL_DATE_SUCCESS:
      return state.mergeDeep({ submitted: true, loading: false });

    case POST_MOBILE_RENEWAL_DATE_FAILURE:
      return state.mergeDeep({ loading: false, error: action.error });

    default:
      return state;
  }
};

export { mobileRenewalReducer };
