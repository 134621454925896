import Immutable from 'immutable';
import { allComplete } from './validation';
import validator from './validation';

import {
  UPDATE_MOBILE_CONTRACT_RENEWAL_FIELD,
  POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST,
  POST_MOBILE_CONTRACT_RENEWAL_DATES_SUCCESS,
  POST_MOBILE_CONTRACT_RENEWAL_DATES_FAILURE,
  REMOVE_MOBILE_CONTRACT_RENEWAL,
  VALIDATE_MOBILE_CONTRACT_RENEWAL,
} from './constants';

const defaultContract = { mobile: '', date: '', errors: {} };

const initialState = Immutable.fromJS({
  contracts: [defaultContract],
  loading: false,
  formComplete: false,
  submitted: false,
  error: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MOBILE_CONTRACT_RENEWAL_FIELD: {
      const contracts = state.get('contracts').toJS();
      if (action.i >= contracts.length) {
        contracts.push({
          ...defaultContract,
          ...(action.name ? { [action.name]: action.value } : {}),
        });
      } else {
        contracts[action.i][action.name] = action.value;
      }

      const formComplete = !action.name
        ? false
        : validator(contracts).formComplete;
      return state.merge({ contracts, formComplete });
    }

    case REMOVE_MOBILE_CONTRACT_RENEWAL: {
      const contracts = state.get('contracts').toJS();
      const i = action.i;
      const reduced = [].concat(contracts.slice(0, i), contracts.slice(i + 1));
      return state.merge({
        contracts: reduced,
        formComplete: allComplete(reduced),
      });
    }

    case VALIDATE_MOBILE_CONTRACT_RENEWAL: {
      return state.merge({
        formComplete: action.formComplete,
        contracts: action.contracts,
      });
    }

    case POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST:
      return state.mergeDeep({ loading: true, error: null });

    case POST_MOBILE_CONTRACT_RENEWAL_DATES_SUCCESS:
      return state.mergeDeep({ submitted: true, loading: false });

    case POST_MOBILE_CONTRACT_RENEWAL_DATES_FAILURE:
      return state.mergeDeep({ loading: false, error: action.error });

    default:
      return state;
  }
}
