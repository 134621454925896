import Immutable from 'immutable';
import * as types from './types';
import {
  MAX_SIM_SELECTION,
  tariffStatus,
} from 'app/redux/modules/Mobile/constants';
import { v4 as uuid } from 'uuid';

const initialState = Immutable.fromJS({
  tariffs: {
    status: undefined,
    error: undefined,
    nodes: [],
  },
  spendCapDialog: {
    open: false,
    spendCapSelected: false,
  },
  numberOptionsDialog: {
    open: false,
    values: {
      keepMyNumber: false,
      cancelOldContract: false,
    },
    errors: {},
  },
  selectedTariffLabel: undefined,
  selectedProducts: [],
  secondMobileDialogOpen: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TARIFFS_REQUEST:
      return state.merge({ tariffs: { status: tariffStatus.LOADING } });
    case types.GET_TARIFFS_ERROR:
      return state.merge({
        tariffs: { status: tariffStatus.ERROR, error: action.error },
      });
    case types.GET_TARIFFS_SUCCESS:
      return state.mergeDeep({
        tariffs: {
          status: tariffStatus.SUCCESS,
          nodes: action.tariffs,
        },
      });
    case types.SET_SELECTED_TARIFF_LABEL: {
      const selectedProducts = state.get('selectedProducts').toJS();
      if (selectedProducts.length === 0) {
        state = state.merge({ selectedProducts: [newMobileProduct()] });
      }
      return state.set('selectedTariffLabel', action.label);
    }
    case types.REMOVE_SELECTED_MOBILE_PRODUCT: {
      const selectedProducts = state.get('selectedProducts').toJS();
      if (selectedProducts.length <= 1) {
        state = state.set('selectedTariffLabel', undefined);
      }
      return state.merge({
        selectedProducts: selectedProducts.filter(
          ({ id }) => id !== action.productId
        ),
      });
    }
    case types.ADD_MOBILE_PRODUCT: {
      const selectedProducts = state.get('selectedProducts').toJS();
      if (selectedProducts.length >= MAX_SIM_SELECTION) return state;
      return state.merge({
        selectedProducts: [...selectedProducts, newMobileProduct()],
      });
    }
    case types.OPEN_SPEND_CAP_DIALOG:
      return state.merge({
        spendCapDialog: {
          open: true,
          productId: action.productId,
          spendCapSelected: action.spendCapSelected,
        },
      });
    case types.UPDATE_SPEND_CAP_SELECTED:
      return state.mergeDeep({
        spendCapDialog: {
          spendCapSelected: action.spendCapSelected,
        },
      });
    case types.SUBMIT_SPEND_CAP_DIALOG:
      return updateSpendCap(state).setIn(['spendCapDialog', 'open'], false);
    case types.CLOSE_SPEND_CAP_DIALOG:
      return state.mergeDeep({
        spendCapDialog: {
          open: false,
        },
      });
    case types.OPEN_NUMBER_OPTIONS_DIALOG:
      return state.merge({
        numberOptionsDialog: {
          open: true,
          productId: action.productId,
          values: action.values,
          errors: {},
        },
      });
    case types.UPDATE_NUMBER_OPTIONS_DIALOG_FIELD:
      return state.mergeDeep({
        numberOptionsDialog: {
          values: {
            [action.field]: action.value,
          },
        },
      });
    case types.UPDATE_NUMBER_OPTIONS_DIALOG_ERRORS:
      return state.setIn(['numberOptionsDialog', 'errors'], action.errors);
    case types.CLOSE_NUMBER_OPTIONS_DIALOG:
      return state.mergeDeep({
        numberOptionsDialog: {
          open: false,
        },
      });
    case types.SUBMIT_NUMBER_OPTIONS_DIALOG:
      return updateProductValues(state, action.productId, action.values).setIn(
        ['numberOptionsDialog', 'open'],
        false
      );
    case types.PRESELECT_SUCCESS:
      return state.mergeDeep(action.preselectState);
    case types.OPEN_SECOND_MOBILE_DIALOG:
      return state.merge({ secondMobileDialogOpen: true });
    case types.CLOSE_SECOND_MOBILE_DIALOG:
      return state.merge({ secondMobileDialogOpen: false });
    default:
      return state;
  }
};

const updateSpendCap = (state) => {
  const { productId, spendCapSelected } = state.get('spendCapDialog').toJS();
  const valuesToUpdate = { budgetControl: spendCapSelected };

  return updateProductValues(state, productId, valuesToUpdate);
};

const updateProductValues = (state, productId, valuesToUpdate) => {
  const selectedProducts = state.get('selectedProducts').toJS();
  const index = selectedProducts.findIndex(
    (product) => product.id === productId
  );
  if (index === -1) return state;

  selectedProducts[index].options.values = valuesToUpdate;
  return state.mergeDeep({ selectedProducts });
};

export const newMobileProduct = () => ({
  id: uuid(),
  options: {
    values: {
      keepMyNumber: false,
      cancelOldContract: false,
      budgetControl: false,
      existingNumber: '',
      pacCode: '',
      stacCode: '',
    },
  },
});
