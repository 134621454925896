import * as actionTypes from './types';

export const toggleMobileNavigation = () => ({
  type: actionTypes.TOGGLE_MOBILE_NAVIGATION,
});
export const toggleHeroVideo = () => ({ type: actionTypes.SET_HERO_VIDEO });

export const setAppointmentType = (appointmentType) => ({
  type: actionTypes.SET_APPOINTMENT_TYPE,
  appointmentType,
});

export const setSupport = (support) => ({
  type: actionTypes.SET_SUPPORT,
  support,
});
