import React from 'react';
import PropTypes from 'prop-types';
import { connector } from 'redux/utils/connector';
import UIComponent from 'components/modules/HomeInsuranceRenewal';
import UIUnknownComponent from 'components/modules/HomeInsuranceRenewal/unknown';
import { STORE_NAME } from './constants';
import { EligibilityServiceApi } from '../Eligibility/api';
import { ApplicationApi } from 'redux/modules/App/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';

const EligibilitySwitchUI = ({ eligible, ...props }) => {
  switch (eligible) {
    case true:
      return <UIComponent {...props} />;
    case false:
      return null;
    default:
      return <UIUnknownComponent {...props} />;
  }
};

EligibilitySwitchUI.propTypes = {
  eligible: PropTypes.bool.isRequired,
};

export default connector(STORE_NAME, (state) => ({
  eligible:
    (ApplicationApi.isRemoteSession(state) &&
      RemoteSessionApi.isMainPeerDriving(state)) ||
    EligibilityServiceApi.isEligibleForHomeInsurance(state),
}))(EligibilitySwitchUI);
