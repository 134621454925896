import moment from 'moment';
import { validateGBMobileNumber } from 'redux/utils/validateGBNumber';
import { createMomentDateFromShortDate } from './lib';

export const allComplete = (list) =>
  list.every(({ errors: { date, mobile } }) => {
    return date && date.complete && mobile && mobile.complete;
  });

const validateDate = (date) => {
  const now = moment();
  const futureDate = moment().add(24, 'months');
  const dateError = {
    complete: false,
    error: 'Please enter a date in the next 24 months',
  };
  const formatError = { complete: false, error: 'Please enter a valid date' };

  if (!date) return { complete: false };
  if (date.length < 7) return formatError;

  const [month] = date.split('-');
  if (parseInt(month, 10) > 12) return formatError;

  const parsed = createMomentDateFromShortDate(date);

  if (parsed.isBefore(now) || parsed.isAfter(futureDate)) return dateError;

  return { complete: true };
};

const validateMobile = (mobile) => {
  if (!mobile) return { complete: false };
  return validateGBMobileNumber(mobile)
    ? { complete: true }
    : { complete: false, error: 'Please enter a valid UK mobile' };
};

export default (contracts) => {
  const validated = contracts.map((c) => ({
    ...c,
    errors: {
      date: validateDate(c.date),
      mobile: validateMobile(c.mobile),
    },
  }));

  return {
    contracts: validated,
    formComplete: allComplete(validated),
  };
};
