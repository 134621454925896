export const STORE_NAME = 'HomeInsuranceRenewal';

export const UPDATE_HOME_INSURANCE_RENEWAL_FIELD =
  'UPDATE_HOME_INSURANCE_RENEWAL_FIELD';
export const POST_HOME_INSURANCE_RENEWAL_DATE_SUCCESS =
  'POST_HOME_INSURANCE_RENEWAL_DATE_SUCCESS';
export const POST_HOME_INSURANCE_RENEWAL_DATE_REQUEST =
  'POST_HOME_INSURANCE_RENEWAL_DATE_REQUEST';
export const POST_HOME_INSURANCE_RENEWAL_DATE_FAILURE =
  'POST_HOME_INSURANCE_RENEWAL_DATE_FAILURE';
export const VALIDATE_HOME_INSURANCE_RENEWAL =
  'VALIDATE_HOME_INSURANCE_RENEWAL';
