import { STORE_NAME } from './constants';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { getCurrentServiceList } from './utils';

export const getProviders = (state) => {
  return state.getIn([STORE_NAME, 'providers'], []).toJS();
};

export const getFieldValues = (state) => {
  return state.getIn([STORE_NAME, 'fields']).toJS();
};

export const getCurrentServicesList = (state) => {
  const serviceFields = getFieldValues(state);
  const energyCombined = getCombinedServices(state);
  const selectedProducts = state.getIn([
    STORE_NAME,
    'summary',
    'breakdown',
    'services',
  ]);
  return getCurrentServiceList({
    serviceFields,
    energyCombined,
    selectedProducts:
      selectedProducts && selectedProducts.toJS().map(({ name }) => name),
  });
};

export const getFieldValuesArray = (state) => {
  const fields = state.getIn([STORE_NAME, 'fields'], null).toJS();
  const { products } = OrderSummaryApi.getApplication(state);
  if (!products) return null;

  const currentServices = getCurrentServicesList(state);
  return Object.entries(fields)
    .filter(([service]) => currentServices.includes(service))
    .map(([service, values]) => ({
      type: service,
      ...values,
    }));
};

export const getProviderValues = (state, service) => {
  const values = state.getIn([STORE_NAME, 'fields', service], null);

  if (values) {
    return values.toJS();
  }

  return values;
};

export const getCombinedServices = (state) => {
  return state.getIn([STORE_NAME, 'combinedServices']);
};

export const editDialogOpen = (state) => {
  return state.getIn([STORE_NAME, 'editDialogOpen']);
};

export const getSummary = (state) => {
  const summary = state.getIn([STORE_NAME, 'summary']);
  if (summary) return summary.toJS();
};

export const isSummaryLoading = (state) => {
  return state.getIn([STORE_NAME, 'summaryLoading']);
};
