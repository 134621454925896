import Immutable from 'immutable';
import * as actionTypes from './types';

const initialState = Immutable.fromJS({
  mobileNavigation: false,
  addresses: [],
  videoOpen: false,
  appointmentType: undefined,
  support: null,
});

export default function LandingPageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_MOBILE_NAVIGATION:
      return state.merge({
        mobileNavigation: !state.mobileNavigation,
      });

    case actionTypes.SET_HERO_VIDEO:
      return state.merge({
        videoOpen: !state.get('videoOpen'),
      });

    case actionTypes.SET_APPOINTMENT_TYPE:
      return state.merge({
        appointmentType: action.appointmentType,
      });

    case actionTypes.SET_SUPPORT:
      return state.set('support', action.support);

    default:
      return state;
  }
}
