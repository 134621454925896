import {
  UPDATE_MOBILE_CONTRACT_RENEWAL_FIELD,
  POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST,
  POST_MOBILE_CONTRACT_RENEWAL_DATES_SUCCESS,
  POST_MOBILE_CONTRACT_RENEWAL_DATES_FAILURE,
  REMOVE_MOBILE_CONTRACT_RENEWAL,
  VALIDATE_MOBILE_CONTRACT_RENEWAL,
} from './constants';
import validator from './validation';

export const updateField = (i, name, value) => ({
  type: UPDATE_MOBILE_CONTRACT_RENEWAL_FIELD,
  name,
  value,
  i,
});
export const submit = () => ({
  type: POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST,
});
export const requestComplete = (savings) => ({
  type: POST_MOBILE_CONTRACT_RENEWAL_DATES_SUCCESS,
  savings,
});
export const requestFailed = (error) => ({
  type: POST_MOBILE_CONTRACT_RENEWAL_DATES_FAILURE,
  error,
});
export const remove = (i) => ({ type: REMOVE_MOBILE_CONTRACT_RENEWAL, i });
export const validate = (contracts) => ({
  type: VALIDATE_MOBILE_CONTRACT_RENEWAL,
  ...validator(contracts),
});
