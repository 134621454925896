import PropTypes from 'prop-types';
import { price } from 'app/constants/propTypes';

export const STORE_NAME = 'Mobile';

export const universalBenefits = [
  'Unlimited minutes and texts',
  'Monthly rolling contract',
  'Strong signal with the EE network',
];

export const tariffLabels = {
  UNLIMITED_PLUS: 'UNLIMITED_PLUS',
  ESSENTIAL: 'ESSENTIAL',
  UNLIMITED: 'UNLIMITED',
  VALUE: 'VALUE',
};

export const tariffStatus = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const defaultTariffError =
  'Sorry we encountered an error while retrieving the mobile tariffs.';

export const codeTypes = {
  PAC: 'pac',
  STAC: 'stac',
};

export const codeErrors = {
  existingNumber: 'Please provide a valid mobile number.',
  [codeTypes.PAC]: 'Please provide a valid PAC code.',
  [codeTypes.STAC]: 'Please provide a valid STAC code.',
};

export const tariffPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dataUnits: PropTypes.string.isRequired,
  price: price.isRequired,
  discountedPrice: price.isRequired,
});

export const productPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    values: PropTypes.shape({
      budgetControl: PropTypes.bool,
      keepMyNumber: PropTypes.bool,
      cancelOldContract: PropTypes.bool,
      pacCode: PropTypes.string,
      stacCode: PropTypes.string,
      existingNumber: PropTypes.string,
    }).isRequired,
  }).isRequired,
  discounted: PropTypes.bool,
});

export const MAX_SIM_SELECTION = 4;

export const multiSimDeals = {
  UNLIMITED_PLUS: {
    minimumSimsRequired: 1,
    maximumSimsDiscounted: 3,
  },
  UNLIMITED: {
    minimumSimsRequired: 1,
    maximumSimsDiscounted: 3,
  },
};
