import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';
import * as HomeAuditApi from '../api';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import CollectionForm from 'components/modules/HomeAudit/CollectionForm';

const CollectionFormContainer = ({ service, enabled, ...props }) => {
  const providers = useSelector((state) =>
    HomeAuditApi.getProviders(state, service)
  );
  if (!enabled) return null;

  return <CollectionForm {...props} service={service} providers={providers} />;
};

const mapStateToProps = (state) => ({
  enabled: FeatureFlagsApi.getFlag(state, FLAGS.HOMEAUDIT_COLLECTION),
  fieldValues: HomeAuditApi.getFieldValues(state),
  providers: HomeAuditApi.getProviders(state),
  combinedServices: HomeAuditApi.getCombinedServices(state),
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(actions, dispatch)
)(CollectionFormContainer);
