export const STORE_NAME = 'MobileContractRenewal';

export const UPDATE_MOBILE_CONTRACT_RENEWAL_FIELD =
  'UPDATE_MOBILE_CONTRACT_RENEWAL_FIELD';
export const POST_MOBILE_CONTRACT_RENEWAL_DATES_SUCCESS =
  'POST_MOBILE_CONTRACT_RENEWAL_DATES_SUCCESS';
export const POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST =
  'POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST';
export const POST_MOBILE_CONTRACT_RENEWAL_DATES_FAILURE =
  'POST_MOBILE_CONTRACT_RENEWAL_DATES_FAILURE';
export const REMOVE_MOBILE_CONTRACT_RENEWAL = 'REMOVE_MOBILE_CONTRACT_RENEWAL';
export const VALIDATE_MOBILE_CONTRACT_RENEWAL =
  'VALIDATE_MOBILE_CONTRACT_RENEWAL';
