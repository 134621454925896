export const STORE_NAME = 'HomeAudit';

export const LABEL_AMOUNT = 'amount';
export const LABEL_PROVIDER = 'provider';
export const LABEL_DATE = 'date';
export const LABEL_DATE_IGNORE = 'ignoreDate';

export const SERVICES = {
  GAS: 'gas',
  ELECTRICITY: 'electricity',
  BROADBAND: 'broadband',
  MOBILE: 'mobile',
  INSURANCE: 'boilerInsurance',
  HOME_INSURANCE: 'homeInsurance',
  GAS_ELEC: 'gasAndElec',
};

export const applicationServiceMap = {
  [SERVICES.GAS]: 'gas',
  [SERVICES.ELECTRICITY]: 'electricity',
  [SERVICES.BROADBAND]: 'broadband',
  [SERVICES.MOBILE]: 'mobiles',
  [SERVICES.INSURANCE]: 'boilerInsurance',
};

export const COMBINED_MAP = {
  [SERVICES.GAS_ELEC]: [SERVICES.GAS, SERVICES.ELECTRICITY],
};

export const serviceLabelMap = {
  [SERVICES.GAS]: 'gas',
  [SERVICES.ELECTRICITY]: 'electricity',
  [SERVICES.MOBILE]: 'mobile',
  [SERVICES.BROADBAND]: 'broadband',
  [SERVICES.INSURANCE]: 'insurance',
  [SERVICES.GAS_ELEC]: 'energy',
};

export const serviceTitleMap = {
  [SERVICES.GAS]: 'Gas',
  [SERVICES.ELECTRICITY]: 'Electricity',
  [SERVICES.MOBILE]: 'Mobile',
  [SERVICES.BROADBAND]: 'Broadband',
  [SERVICES.INSURANCE]: 'Boiler & Home Cover',
  [SERVICES.GAS_ELEC]: 'Energy',
};

export const PROVIDERS = {
  [SERVICES.GAS]: [
    'British Gas',
    'E.On',
    'EDF Energy',
    'nPower',
    'SSE',
    'Scottish Power',
    'Bulb',
    'Other',
  ],
  [SERVICES.ELECTRICITY]: [
    'British Gas',
    'E.On',
    'EDF Energy',
    'nPower',
    'SSE',
    'Scottish Power',
    'Bulb',
    'Other',
  ],
  [SERVICES.BROADBAND]: [
    'BT',
    'Sky',
    'Virgin Media',
    'TalkTalk',
    'Plusnet',
    'Vodafone ',
    'EE',
    'Other',
  ],
  [SERVICES.MOBILE]: [
    'EE',
    'O2',
    'Tesco',
    'Mobile',
    'Virgin Mobile',
    'Vodafone',
    'Giff Gaff',
    'Other',
  ],
  [SERVICES.INSURANCE]: [
    'British Gas',
    'HomeServe',
    'Corgi Homeplan',
    'EDF',
    'SSE',
    'HomeTree',
    'Other',
  ],
  [SERVICES.GAS_ELEC]: [
    'British Gas',
    'E.On',
    'EDF Energy',
    'nPower',
    'SSE',
    'Scottish Power',
    'Bulb',
    'Other',
  ],
};
