export const GET_TARIFFS_REQUEST = 'GET_TARIFFS_REQUEST';
export const GET_TARIFFS_SUCCESS = 'GET_TARIFFS_SUCCESS';
export const GET_TARIFFS_ERROR = 'GET_TARIFFS_ERROR';

export const SET_SELECTED_TARIFF_LABEL = 'SET_SELECTED_TARIFF_LABEL';
export const REMOVE_SELECTED_MOBILE_PRODUCT = 'REMOVE_SELECTED_MOBILE_PRODUCT';
export const ADD_MOBILE_PRODUCT = 'ADD_MOBILE_PRODUCT';

export const PUT_MOBILE_SELECTION_REQUEST = 'PUT_MOBILE_SELECTION_REQUEST';
export const PUT_MOBILE_SELECTION_SUCCESS = 'PUT_MOBILE_SELECTION_SUCCESS';

export const OPEN_SPEND_CAP_DIALOG = 'OPEN_SPEND_CAP_DIALOG';
export const UPDATE_SPEND_CAP_SELECTED = 'UPDATE_SPEND_CAP_SELECTED';
export const CLOSE_SPEND_CAP_DIALOG = 'CLOSE_SPEND_CAP_DIALOG';
export const SUBMIT_SPEND_CAP_DIALOG = 'SUBMIT_SPEND_CAP_DIALOG';

export const OPEN_NUMBER_OPTIONS_DIALOG = 'OPEN_NUMBER_OPTIONS_DIALOG';
export const UPDATE_NUMBER_OPTIONS_DIALOG_FIELD =
  'UPDATE_NUMBER_OPTIONS_DIALOG_FIELD';
export const UPDATE_NUMBER_OPTIONS_DIALOG_ERRORS =
  'UPDATE_NUMBER_OPTIONS_DIALOG_ERRORS';
export const CLOSE_NUMBER_OPTIONS_DIALOG = 'CLOSE_NUMBER_OPTIONS_DIALOG';
export const VALIDATE_AND_SUBMIT_NUMBER_OPTIONS_DIALOG =
  'VALIDATE_NUMBER_OPTIONS_DIALOG';
export const SUBMIT_NUMBER_OPTIONS_DIALOG = 'SUBMIT_NUMBER_OPTIONS_DIALOG';

export const PRESELECT_SUCCESS = 'MOBILE_PRESELECT_SUCCESS';

export const OPEN_SECOND_MOBILE_DIALOG = 'OPEN_SECOND_MOBILE_DIALOG';
export const CLOSE_SECOND_MOBILE_DIALOG = 'CLOSE_SECOND_MOBILE_DIALOG';
