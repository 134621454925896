import { getNumberOptionsDialogState } from 'app/redux/modules/Mobile/api';
import { connector } from 'app/redux/utils/connector';
import { STORE_NAME } from '../constants';
import {
  closeNumberOptionsDialog,
  updateNumberOptionsDialogField,
  validateAndSubmitNumberOptionsDialog,
} from 'app/redux/modules/Mobile/actions';
import NumberOptionsDialog from 'modules/Mobile/NumberOptionsDialog';

const actions = {
  onClose: closeNumberOptionsDialog,
  updateValue: updateNumberOptionsDialogField,
  submit: validateAndSubmitNumberOptionsDialog,
};

export default connector(
  STORE_NAME,
  getNumberOptionsDialogState,
  actions
)(NumberOptionsDialog);
