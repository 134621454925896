import {
  POST_LEAD_CAPTURE_FAILURE,
  POST_LEAD_CAPTURE_REQUEST,
  POST_LEAD_CAPTURE_SUCCESS,
  RESET_FORM,
  SET_LEAD_CAPTURE_DIALOG,
  UPDATE_FORM,
} from './constants';

export const submit = () => ({
  type: POST_LEAD_CAPTURE_REQUEST,
});
export const requestComplete = () => ({
  type: POST_LEAD_CAPTURE_SUCCESS,
});
export const requestFailed = (error) => ({
  type: POST_LEAD_CAPTURE_FAILURE,
  error,
});

export const updateForm = (field, value) => ({
  type: UPDATE_FORM,
  field,
  value,
});

export const resetForm = () => ({
  type: RESET_FORM,
});

export const setLeadCaptureDialog = (open) => ({
  type: SET_LEAD_CAPTURE_DIALOG,
  open,
});
