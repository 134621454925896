import { authenticatedPost } from 'redux/utils/request';
import { createMomentDateFromShortDate } from '../lib';

export default (contracts) =>
  authenticatedPost('products/mobile/contract-renewal', {
    contracts: contracts.map(({ mobile, date }) => ({
      renewalDate: createMomentDateFromShortDate(date).format('YYYY-MM-DD'),
      mobileNumber: mobile,
    })),
  });
