import { connector } from 'redux/utils/connector';

import { STORE_NAME } from './constants';
import LeadCapture from 'app/components/modules/LeadCapture';
import { resetForm, setLeadCaptureDialog, submit, updateForm } from './actions';
import { PartnerLoginApi } from '../PartnerLogin/api';

import { getFormError, getFormFields, getFormSuccess } from './api';

export default connector(
  STORE_NAME,
  (state, props) => ({
    error: getFormError(state),
    submitSuccess: getFormSuccess(state),
    values: getFormFields(state),
    partnerName: PartnerLoginApi.getPartnerName(state),
    isDisabled: props.isDisabled,
  }),
  {
    onSubmit: submit,
    onSetValue: updateForm,
    onReset: resetForm,
    onClose: () => setLeadCaptureDialog(false),
  }
)(LeadCapture);
