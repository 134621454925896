import { STORE_NAME } from './constants';

export class MobileContractRenewalAPI {
  static getRenewalContracts(store) {
    return store
      .get(STORE_NAME)
      .get('contracts')
      .toJS();
  }
}
