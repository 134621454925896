import React, { useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, Redirect } from 'react-router-dom';
import * as actionCreators from 'redux/modules';
import PartnerLogin from 'redux/modules/PartnerLogin/container';
import EntryForm from 'redux/modules/EntryForm/container';
import { ApplicationApi } from 'redux/modules/App/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import { SUPPORT_SELECTION_OPTIONS } from 'redux/modules/LandingPage/constants';
import { FeatureFlagsApi } from '../../FeatureFlags/api';
import { FLAGS } from '../../FeatureFlags/constants';

import {
  APPOINTMENT_TYPE,
  APPOINTMENT_TYPE_OPTIONS,
  APPOINTMENT_TYPE_OPTIONS_NEW,
} from '../constants';
import { LandingPageApi } from '../api';
import * as actions from '../actions';

const useAppointmentType = () => {
  const appointmentType = useSelector(LandingPageApi.getAppointmentType);
  const restoreEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.STATE_RESTORE)
  );
  const dispatch = useDispatch();
  const setAppointmentType = useCallback(
    (type) => dispatch(actions.setAppointmentType(type)),
    [dispatch]
  );

  const splitRemote = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.REMOTE_SUPPORT)
  );
  const appointmentTypeOptions = splitRemote
    ? APPOINTMENT_TYPE_OPTIONS_NEW
    : APPOINTMENT_TYPE_OPTIONS;
  const filteredAppointmentTypeOptions = appointmentTypeOptions.filter(
    (option) => {
      return !(option.type === APPOINTMENT_TYPE.RESTORE && !restoreEnabled);
    }
  );

  return {
    appointmentType,
    appointmentTypeOptions: filteredAppointmentTypeOptions,
    onChangeAppointmentType: setAppointmentType,
  };
};

const LandingPageSwitchUI = (props) => {
  const newOrganicEntry = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.LANDING_PAGE_NEW)
  );
  const history = useHistory();
  const getStarted = useCallback(() => {
    history.push('/bundles');
  }, [history]);
  const appointmentTypeProps = useAppointmentType();
  const isApplicationCreated = !!useSelector(
    ApplicationApi.getCurrentApplication
  );
  const isRemoteSessionStarted = useSelector(RemoteSessionApi.isStarted);
  const selectedSupport = useSelector(LandingPageApi.getSupportSelection);

  const shouldRedirectToPresentation = () =>
    appointmentTypeProps.appointmentType === APPOINTMENT_TYPE.FACE_TO_FACE &&
    (selectedSupport === SUPPORT_SELECTION_OPTIONS.noSupport ||
      selectedSupport === SUPPORT_SELECTION_OPTIONS.present);

  const shouldRedirectToOrganic = () =>
    (appointmentTypeProps.appointmentType === APPOINTMENT_TYPE.FACE_TO_FACE &&
      selectedSupport === SUPPORT_SELECTION_OPTIONS.remote) ||
    newOrganicEntry;

  if (!isApplicationCreated) {
    return <PartnerLogin {...appointmentTypeProps} />;
  }

  if (
    appointmentTypeProps.appointmentType === APPOINTMENT_TYPE.REMOTE &&
    !isRemoteSessionStarted
  ) {
    return <Redirect to={'/remotely/start'} />;
  }

  if (
    selectedSupport === SUPPORT_SELECTION_OPTIONS.remote &&
    !isRemoteSessionStarted
  ) {
    return <Redirect to={'/remotely/start/supported'} />;
  }

  if (shouldRedirectToPresentation()) {
    return <Redirect to="/presentation" />;
  }

  if (shouldRedirectToOrganic()) {
    return <Redirect to="/organic" />;
  }

  return <EntryForm {...props} getStarted={getStarted} />;
};

export default connect(undefined, (dispatch) =>
  bindActionCreators(
    {
      ...actionCreators.Analytics,
      ...actionCreators.EntryForm,
    },
    dispatch
  )
)(LandingPageSwitchUI);
